// packages
import React from 'react';
import PropTypes from 'prop-types';

// utilities
import cn from 'src/utilities/bem-cn';

// styles
import './styles.scss';

const className = 'product-description-form';
const el = (name) => cn(className, name);

class ProductDescriptionForm extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			expanded: false,
			charactersUsed: 0,
			charactersExceeded: false,
			textareaValue: '',
		};
		this.characterLimit = 100;
		this.customOnFocus = props.onFocus;
		this.customOnBlur = props.onBlur;
		this.t = props.t;
		this.isKeyboardNavigation = props.isKeyboardNavigation;

		// This is used to check if setState should be called (Fixes the following warning)
		// Warning: Can't perform a React state update on an unmounted component. This is a no-op, but it indicates a memory leak in your application. To fix, cancel all subscriptions and asynchronous tasks in the componentWillUnmount method.
		this.mounted = false;
	}

	componentDidMount() {
		this.mounted = true;
	}

	componentWillUnmount() {
		this.mounted = false;
	}

	onFocus = () => {
		if (this.mounted) {
			this.setState({ expanded: true }, () => {
				// Using set timeout so window.scroll will work
				setTimeout(() => window.scroll(0, 0), 250);
				if (this.customOnFocus) {
					this.customOnFocus();
				}
			});
		}
	};

	onBlur = () => {
		// Setting a short timeout so the submit button click event has time to register
		setTimeout(() => {
			if (this.mounted) {
				this.setState({ expanded: false }, () => {
					if (this.customOnBlur) {
						this.customOnBlur();
					}
				});
			}
		}, 250);
	};

	onChange = (e) => {
		if (this.mounted) {
			this.setState({
				charactersUsed: e.target.value.length,
				charactersExceeded: e.target.value.length > this.characterLimit,
				textareaValue: e.target.value,
			});
		}
	};

	onEditHandler = (e) => {
		e.preventDefault();
		this.inputRef.focus();
	};

	onSubmitHandler = (e) => {
		const { handleSubmit, productData } = this.props;
		const { textareaValue } = this.state;
		e.preventDefault();

		const fieldOneData = productData.fieldOne[0];

		// get the variation id
		let variationId = null;
		if (fieldOneData.url) {
			variationId = fieldOneData.variations.find((v) => v.url === fieldOneData.url).id;
		}

		// add product data to the clickpoints
		handleSubmit([
			{
				productId: productData.id,
				assetVariationId: variationId,
				message: textareaValue,
			},
		]);
	};

	renderTextArea = () => {
		const value = this.state.textareaValue;
		const placeholderText = this.t('type-your-response-here');
		const textareaDisplayLimit = 30;
		const textareaValue = this.state.expanded
			? value
			: value.length > textareaDisplayLimit
			? `${value.substring(0, textareaDisplayLimit)}…`
			: value;
		return (
			<textarea
				className={el(`textarea${this.state.expanded ? ' expanded' : ''}`)}
				value={textareaValue}
				maxLength={this.characterLimit}
				onFocus={this.onFocus}
				onBlur={this.onBlur}
				onChange={this.onChange}
				placeholder={placeholderText}
				ref={this.assignInputRef}
				style={{ ...(!this.isKeyboardNavigation && { outline: 'none' }) }}
			/>
		);
	};

	renderEditButton = () => {
		const { charactersUsed, expanded } = this.state;
		const showEditButton = !expanded && charactersUsed > 0;
		const editButtonLabel = this.t('edit');
		if (showEditButton) {
			return (
				<a className={el('edit')} href="#" onClick={this.onEditHandler}>
					{editButtonLabel}
				</a>
			);
		}
		return false;
	};

	renderSubmitButton = () => {
		const { charactersUsed, expanded } = this.state;
		const isDisabled = charactersUsed === 0;
		const isGreen = charactersUsed > 0;
		return (
			<button
				aria-label="Submit"
				aria-disabled={isDisabled}
				type="button"
				disabled={isDisabled}
				className={el(`submit${isGreen ? ' enabled' : ''}${expanded ? ' expanded' : ''}`)}
				onClick={this.onSubmitHandler}
			>
				→
			</button>
		);
	};

	assignInputRef = (node) => {
		this.inputRef = node;
	};

	render() {
		return (
			<form className={className}>
				{this.renderCharacterCounter()}
				{this.renderTextArea()}
				{this.renderEditButton()}
				{this.renderSubmitButton()}
			</form>
		);
	}
}

ProductDescriptionForm.propTypes = {
	handleSubmit: PropTypes.func.isRequired,
	productData: PropTypes.object.isRequired,
	t: PropTypes.func.isRequired,
	onFocus: PropTypes.func,
	onBlur: PropTypes.func,
	isKeyboardNavigation: PropTypes.bool,
};

export default ProductDescriptionForm;
