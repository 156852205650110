import React from 'react';
import PropTypes from 'prop-types';
import { shuffle } from 'src/utilities/lodash';
import { getAssetVariationUrl, productsContainVideo, resetKeyboardNavigationFocus } from 'src/utilities/misc';
import utils from 'src/utilities/product-data-util';
import Stack from 'src/components/shared/Stack';
import Commitment from 'src/components/shared/Commitment';

const localBattleGroundState = { commitmentAnimationPlayed: false };

// Battleground component - Takes array of products and shows the stack of products with commitments calls back with { interests, commitments } object
class Battleground extends React.Component {
	constructor(props) {
		super(props);
		this.$interests = [];
		this.$commitmentProducts = [];
		this.stackRef = React.createRef();
		this.state = {
			best: null,
			renderCommitment: false,
			commitment: false,
			/* Results */
			interests: [],
			commitments: [],
			considered: [],
			combinationsExpected: null,
			combinationsFulfilled: null,
		};
	}

	componentDidMount() {
		localBattleGroundState.commitmentAnimationPlayed = false;
	}

	getShowCommitmentAtTheEnd = (section) => {
		const settingsIndex = section.settings.findIndex((item) => item.label === 'show_commitment_at_the_end');
		if (settingsIndex > -1) {
			return section.settings[settingsIndex].value === 'true';
		}
		return true;
	};

	checkInterestEnd = (productId) => {
		const { renderCommitment, interests } = this.state;
		const { products, showCommitments } = this.props;

		if (interests.length === products.length && (!renderCommitment || !showCommitments)) {
			if (this.$commitmentProducts.length > 1 && showCommitments) {
				this.setState({
					renderCommitment: true,
					combinationsExpected: this.$commitmentProducts.length - 1,
					combinationsFulfilled: 0,
				});
			} else {
				this.checkEnd();
			}
		} else {
			const currentProductIndex = products?.findIndex(({ id }) => id === productId);
			const nextProduct = currentProductIndex >= 0 ? products[currentProductIndex + 1] : null;
			const { fieldOneType = '', fieldTwoType = '', fieldThreeType = '' } = nextProduct ?? {};
			// Check whether the next product includes any assets to focus on
			const nextProductHasAsset = nextProduct && [fieldOneType, fieldTwoType, fieldThreeType].includes('asset');
			// Don't reset the focus for commitment screen, because it has it's own focus
			resetKeyboardNavigationFocus({ blurOnly: !nextProductHasAsset });
		}
	};

	checkCommitmentEnd = () => {
		const { combinationsExpected, combinationsFulfilled } = this.state;
		if (combinationsExpected === combinationsFulfilled) {
			this.checkEnd();
		}
	};

	checkEnd = () => {
		const { commitment, interests, considered, best, commitments } = this.state;
		const { products } = this.props;
		if (interests.length === products.length && !commitment) {
			this.props.onResults({
				interests: interests.map((interest) => ({
					...interest,
					considered: considered.indexOf(interest.id) > -1,
				})),
				best,
				commitments,
			});
		}
	};

	handleInterest = async ({ id, localProductId, interest }) => {
		const { currentSection } = this.props;
		const { id: sectionId } = currentSection;
		// Prevent duplications
		const wasPushed = this.$interests.indexOf(id) > -1;
		if (wasPushed) {
			return;
		}
		this.$interests.push(id);
		const setState = (f) => new Promise((resolve) => this.setState(f, resolve));
		if (interest) {
			this.$commitmentProducts.push(id);
		}
		await setState((state) => ({
			interests: state.interests.concat([{ id, localProductId, interest, sectionId }]),
		}));
		this.checkInterestEnd(id);
	};

	handleConsider = (id) => {
		this.setState((state) => ({ considered: state.considered.concat([id]) }));
	};

	handleCommitment = (id) => {
		const { commitmentAnimationPlayed } = localBattleGroundState;
		const { currentSection } = this.props;
		const { id: sectionId } = currentSection;
		this.stackRef?.current?.updateResetDisabledButton();
		if (!commitmentAnimationPlayed) {
			localBattleGroundState.commitmentAnimationPlayed = true;
		}
		this.setState(
			(state) => {
				const p0 = state.commitment;
				const p1 = state.best;
				const won = id;
				const loose = p0 === id ? p1 : p0;
				const { combinationsFulfilled } = state;
				return {
					commitments: state.commitments.concat([{ won, loose, sectionId }]),
					commitment: false,
					best: id,
					combinationsFulfilled: combinationsFulfilled + 1,
				};
			},
			() => this.checkCommitmentEnd(),
		);
	};

	hasDetails = (product) => product.title || product.description || product.price || product.subtitle;

	getDetails = (product) => ({
		type: 'Details',
		content: {
			title: product.title,
			summary: product.description,
			price: product.price,
			subtitle: product.subtitle,
		},
		options: {},
	});

	getProductsView = (products) =>
		products.map((product) => {
			const { id, localProductId } = product;
			return {
				id,
				localProductId,
				...this.getStackProduct(product),
			};
		});

	getInvalidLayoutSections = (product) => {
		const invalidLayoutSections = [];
		if (product && product.fieldOne) {
			invalidLayoutSections[0] = utils.getFieldSection(
				product.fieldOneType,
				product.fieldOne,
				product.fieldOneOptions,
			);
		}
		if (product && product.fieldTwo) {
			invalidLayoutSections[1] = utils.getFieldSection(
				product.fieldTwoType,
				product.fieldTwo,
				product.fieldTwoOptions,
			);
		}
		if (product && product.fieldThree) {
			invalidLayoutSections[2] = utils.getFieldSection(
				product.fieldThreeType,
				product.fieldThree,
				product.fieldThreeOptions,
			);
		}
		return invalidLayoutSections;
	};

	// * CARD STACK
	getStackProduct = (product) => {
		const { ideaScreenLayout, imageSize, imageCropping } = this.props;
		const isImageOnly = ideaScreenLayout === 'imageOnly';
		const isTextOnly = ideaScreenLayout === 'textOnly';
		const isDoubleText = ideaScreenLayout === 'doubleText';
		const isNoTitle = ideaScreenLayout === 'noTitle';
		const isTitleTop = ideaScreenLayout === 'titleTop';
		const isTitleBottom = ideaScreenLayout === 'titleBottom';
		const isSideBySide = ideaScreenLayout === 'sideBySide';
		const isInvalidLayout = ideaScreenLayout === 'invalid_layout' || ideaScreenLayout === '';
		// console.log('-');
		// console.log('BATTLEGROUND: product: ', product);
		// console.log('BATTLEGROUND: ideaScreenLayout: ', ideaScreenLayout);
		// console.log('BATTLEGROUND: isInvalidLayout: ', isInvalidLayout);
		// console.log('-');
		const invalidLayoutSections = this.getInvalidLayoutSections(product);
		const data = {
			type: 'Fixed',
			ideaScreenLayout,
			imageSize,
			imageCropping,
			// TODO: the 'layout' field has been deprecated and is no longer being set on old layouts
			// !! KEEP THIS IN FOR NOW
			...(product && product.layout && product.layout === 'classic'
				? {
						sections: [
							{
								type: 'Images',
								content: (product && product.fieldOne && product.fieldOne.map
									? product.fieldOne
									: []
								).map((asset) => getAssetVariationUrl(asset)),
								options: product && product.fieldOneOptions ? product.fieldOneOptions : {},
							},
							...(this.hasDetails(product) ? [this.getDetails(product)] : []),
						],
				  }
				: {}),
			// !! KEEP THIS IN FOR NOW
			...(product && product.layout && product.layout === 'one-field'
				? {
						sections: [
							utils.getFieldSection(product.fieldOneType, product.fieldOne, product.fieldOneOptions),
							...(this.hasDetails(product) ? [this.getDetails(product)] : []),
						],
				  }
				: {}),
			// All future products are two-field
			...(product && product.layout && product.layout === 'two-field'
				? {
						sections: [
							utils.getFieldSection(product.fieldOneType, product.fieldOne, product.fieldOneOptions),
							...(product.fieldTwoType && product.fieldTwo
								? [
										utils.getFieldSection(
											product.fieldTwoType,
											product.fieldTwo,
											product.fieldTwoOptions,
										),
								  ]
								: []),
							...(this.hasDetails(product) ? [this.getDetails(product)] : []),
						],
				  }
				: {}),
			...(isInvalidLayout && {
				type: 'invalid_layout',
				sections: invalidLayoutSections,
			}),
			...(isImageOnly
				? {
						type: 'imageOnly',
						sections: [
							utils.getFieldSection(product.fieldOneType, product.fieldOne, product.fieldOneOptions),
						],
				  }
				: {}),
			...(isTextOnly
				? {
						type: 'textOnly',
						sections: [
							utils.getFieldSection(product.fieldOneType, product.fieldOne, product.fieldOneOptions),
						],
				  }
				: {}),
			...(isDoubleText
				? {
						type: 'doubleText',
						sections: [
							utils.getFieldSection(product.fieldOneType, product.fieldOne, product.fieldOneOptions),
							utils.getFieldSection(product.fieldTwoType, product.fieldTwo, product.fieldTwoOptions),
						],
				  }
				: {}),
			...(isNoTitle
				? {
						type: 'noTitle',
						sections: [
							utils.getFieldSection(product.fieldOneType, product.fieldOne, product.fieldOneOptions),
							utils.getFieldSection(product.fieldTwoType, product.fieldTwo, product.fieldTwoOptions),
						],
				  }
				: {}),
			...(isTitleTop
				? {
						type: 'titleTop',
						sections: [
							utils.getFieldSection(product.fieldOneType, product.fieldOne, product.fieldOneOptions),
							utils.getFieldSection(product.fieldTwoType, product.fieldTwo, product.fieldTwoOptions),
							utils.getFieldSection(
								product.fieldThreeType,
								product.fieldThree,
								product.fieldThreeOptions,
							),
						],
				  }
				: {}),
			...(isTitleBottom
				? {
						type: 'titleBottom',
						sections: [
							utils.getFieldSection(product.fieldOneType, product.fieldOne, product.fieldOneOptions),
							utils.getFieldSection(product.fieldTwoType, product.fieldTwo, product.fieldTwoOptions),
							utils.getFieldSection(
								product.fieldThreeType,
								product.fieldThree,
								product.fieldThreeOptions,
							),
						],
				  }
				: {}),
			...(isSideBySide
				? {
						type: 'sideBySide',
						sections: [
							utils.getFieldSection(product.fieldOneType, product.fieldOne, product.fieldOneOptions),
							utils.getFieldSection(product.fieldTwoType, product.fieldTwo, product.fieldTwoOptions),
							utils.getFieldSection(
								product.fieldThreeType,
								product.fieldThree,
								product.fieldThreeOptions,
							),
						],
				  }
				: {}),
		};
		// console.log('BATTLEGROUND - getStackProduct - DATA: ' ,data);
		return data;
	};

	// * TRADEOFF
	getCommitmentProduct = (products) => {
		const { ideaScreenLayout, imageSize, imageCropping } = this.props;
		const data = products.map((product) => {
			const isImageOnly = ideaScreenLayout === 'imageOnly';
			const isTextOnly = ideaScreenLayout === 'textOnly';
			const isDoubleText = ideaScreenLayout === 'doubleText';
			const isNoTitle = ideaScreenLayout === 'noTitle';
			const isTitleTop = ideaScreenLayout === 'titleTop';
			const isTitleBottom = ideaScreenLayout === 'titleBottom';
			const isSideBySide = ideaScreenLayout === 'sideBySide';
			const isInvalidLayout = ideaScreenLayout === 'invalid_layout';
			const invalidLayoutSections = this.getInvalidLayoutSections(product);
			return {
				id: product.id,
				ideaScreenLayout,
				imageSize,
				imageCropping,
				...(isInvalidLayout && {
					type: 'invalid_layout',
					sections: invalidLayoutSections,
					stackProduct: this.getStackProduct(product),
				}),
				...(isImageOnly && {
					type: 'imageOnly',
					sections: [
						utils.getFieldSection(product.fieldOneType, product.fieldOne, product.fieldOneOptions, [
							'large',
							'full',
							'medium',
							'thumbnail',
						]),
					],
					stackProduct: this.getStackProduct(product),
				}),
				...(isTextOnly && {
					type: 'textOnly',
					sections: [
						utils.getFieldSection(product.fieldOneType, product.fieldOne, product.fieldOneOptions, [
							'large',
							'full',
							'medium',
							'thumbnail',
						]),
					],
					stackProduct: this.getStackProduct(product),
				}),
				...(isDoubleText && {
					type: 'doubleText',
					sections: [
						utils.getFieldSection(product.fieldOneType, product.fieldOne, product.fieldOneOptions, [
							'large',
							'full',
							'medium',
							'thumbnail',
						]),
					],
					stackProduct: this.getStackProduct(product),
				}),
				...(isNoTitle && {
					type: 'noTitle',
					sections: [
						utils.getFieldSection(product.fieldOneType, product.fieldOne, product.fieldOneOptions, [
							'large',
							'full',
							'medium',
							'thumbnail',
						]),
					],
					stackProduct: this.getStackProduct(product),
				}),
				...(isTitleTop && {
					type: 'titleTop',
					sections: [
						utils.getFieldSection(product.fieldOneType, product.fieldOne, product.fieldOneOptions, [
							'large',
							'full',
							'medium',
							'thumbnail',
						]),
						utils.getFieldSection(product.fieldTwoType, product.fieldTwo, product.fieldTwoOptions, [
							'large',
							'full',
							'medium',
							'thumbnail',
						]),
					],
					stackProduct: this.getStackProduct(product),
				}),
				...(isTitleBottom && {
					type: 'titleBottom',
					sections: [
						utils.getFieldSection(product.fieldOneType, product.fieldOne, product.fieldOneOptions, [
							'large',
							'full',
							'medium',
							'thumbnail',
						]),
						utils.getFieldSection(product.fieldTwoType, product.fieldTwo, product.fieldTwoOptions, [
							'large',
							'full',
							'medium',
							'thumbnail',
						]),
					],
					stackProduct: this.getStackProduct(product),
				}),
				...(isSideBySide && {
					type: 'sideBySide',
					sections: [
						utils.getFieldSection(product.fieldOneType, product.fieldOne, product.fieldOneOptions, [
							'large',
							'full',
							'medium',
							'thumbnail',
						]),
						utils.getFieldSection(product.fieldTwoType, product.fieldTwo, product.fieldTwoOptions, [
							'large',
							'full',
							'medium',
							'thumbnail',
						]),
					],
					stackProduct: this.getStackProduct(product),
				}),
			};
		});

		return data;
	};

	renderCommitment() {
		const { products, study, currentSection } = this.props;
		const { commitment, best } = this.state;

		const bestProduct = products.filter(({ id }) => id === best).pop();
		const commitmentProduct = products.filter(({ id }) => id === commitment).pop();

		if (!best) {
			// ? pick the first one from commitmentProducts and remove it from Array
			const bestId = this.$commitmentProducts.shift();
			this.setState({ best: bestId }); // first liked product
		}
		if (!commitment) {
			const commitmentId = this.$commitmentProducts.shift();
			this.setState({ commitment: commitmentId });
		}
		// Pass in the best product and a commitment product
		if (bestProduct && commitmentProduct) {
			const shuffledProducts = shuffle([bestProduct, commitmentProduct]);
			const productsView = this.getCommitmentProduct(shuffledProducts);

			const { commitmentAnimationPlayed } = localBattleGroundState;

			return (
				<div
					className={`commitment-card-stack-wrapper ${commitmentAnimationPlayed === false ? 'animate' : ''}`}
				>
					<Commitment
						products={productsView}
						onClick={this.handleCommitment}
						study={study}
						currentSection={currentSection}
					/>
				</div>
			);
		}
	}

	render() {
		const { renderCommitment, commitment } = this.state;
		const { currentSection, products } = this.props;
		if (!products) {
			return null;
		}
		const productsView = this.getProductsView(products);

		const hasVideo = productsContainVideo(products);

		const IdeaSwipeStyle = () => {
			if (commitment) {
				return {
					cursor: 'default',
					opacity: 0,
					pointerEvents: 'none',
					position: 'absolute',
					overflow: 'hidden',
					visibility: 'hidden',
				};
			}

			return {};
		};

		return (
			<>
				{renderCommitment !== false && this.renderCommitment()}
				<div style={{ ...IdeaSwipeStyle() }}>
					<Stack
						ref={this.stackRef}
						currentSection={currentSection}
						products={productsView}
						onInterest={this.handleInterest}
						onConsider={this.handleConsider}
						onBeforeSwipe={this.props.onBeforeSwipe ? this.props.onBeforeSwipe : false}
						onSwipeEnd={this.props.onSwipeEnd ? this.props.onSwipeEnd : false}
						renderButtons={this.props.renderButtons ? this.props.renderButtons : false}
						renderCount={isNaN(this.props.renderCount) ? 3 : this.props.renderCount}
						disableSwipe={hasVideo}
						commitment={commitment}
					/>
				</div>
			</>
		);
	}
}

Battleground.propTypes = {
	products: PropTypes.any,
	currentSection: PropTypes.any,
};

export default Battleground;
