// packages
import React, { useEffect, useRef } from 'react';
import parse from 'html-react-parser';
import PropTypes from 'prop-types';
// Localization
import { withTranslation } from 'react-i18next';

// utilities
import * as misc from 'src/utilities/misc';
import cn from 'src/utilities/bem-cn';

// components
import ImageZoom from 'src/components/new/ImageZoom';

// helpers
import { getEmoji } from 'src/components/helpers/Emoji';
import * as pipingUtilities from '../../helpers/Piping';

import './styles.scss';

const className = 'question-label';
const el = (name) => cn(className, name);

const pipedQuestionElementName = 'pipedblock'; // ! MUST BE ALL LOWER CASE

const QuestionLabel = ({ t, study, label, answers, setAssetPreviewModalUrl, productId }) => {
	const labelRef = useRef(null);

	const imageButton = (asset) => (
		<ImageZoom>
			<img
				className={el('preview-photo-icon')}
				alt=""
				src={misc.getAssetVariationUrl(asset, ['large', 'full', 'medium', 'thumbnail'])}
			/>
		</ImageZoom>
	);

	const handleClick = (hasImage, pipedQuestionAnswer) => {
		if (hasImage) {
			document.getElementById('app-content').style.filter = 'blur(3px)';
			setAssetPreviewModalUrl(pipedQuestionAnswer.asset);
		}
	};

	const renderSingleSelect = (pipedQuestion, pipedQuestionAnswer) => {
		const hasImage = pipedQuestionAnswer.asset;
		const showLabel = misc.getQuestionSetting(pipedQuestion, 'image_only') !== 'true';
		const otherOptionValue = pipedQuestionAnswer.isOtherSpecify ? answers[pipedQuestion.id].otherValue : null;
		return (
			<span className={el('pill')} onClick={() => handleClick(hasImage, pipedQuestionAnswer)}>
				{hasImage && imageButton(pipedQuestionAnswer.asset)}
				{(showLabel || !hasImage) && (
					<span className={hasImage ? el('pill-text') : ''}>
						{otherOptionValue || pipedQuestionAnswer.label}
					</span>
				)}
			</span>
		);
	};

	const renderMultiSelect = (pipedQuestion, pipedQuestionAnswer) => {
		const multiSelectAnswer = [];
		pipedQuestionAnswer.forEach((answer, index) => {
			const hasImage = answer.asset;
			const showLabel = misc.getQuestionSetting(pipedQuestion, 'image_only') !== 'true';
			const hasMore = index < pipedQuestionAnswer.length - 1;
			const otherOptionValue = answer.isOtherSpecify
				? answers[productId ? `${pipedQuestion.id}-${productId}` : pipedQuestion.id]?.otherValue
				: null;
			multiSelectAnswer.push(
				<React.Fragment key={index}>
					<span key={index} className={el('pill')}>
						{hasImage && imageButton(answer.asset)}
						{(showLabel || !hasImage) && (
							<span className={hasImage ? el('pill') : ''}>{otherOptionValue || answer.label}</span>
						)}
					</span>
					{hasMore && ', '}
				</React.Fragment>,
			);
		});

		return <>{multiSelectAnswer}</>;
	};

	const getEmojiElement = (emojiName) => {
		const emojiLabel = getEmoji(emojiName);
		const altText = t(emojiName);
		return <img className={el('pill-emoji-image')} src={emojiLabel} alt={altText} />;
	};

	const renderSingleSelectEmoji = (pipedQuestionAnswer) => {
		const emojiElement = getEmojiElement(pipedQuestionAnswer.label, t);
		return (
			<span className={el('pill-emoji')}>
				{emojiElement} {t(pipedQuestionAnswer.label)}
			</span>
		);
	};

	const renderMultiSelectEmoji = (pipedQuestionAnswer) => {
		const multiSelectEmojiAnswer = [];
		pipedQuestionAnswer.forEach((answer, index) => {
			const hasMore = index < pipedQuestionAnswer.length - 1;
			const emojiElement = getEmojiElement(answer.label, t);
			multiSelectEmojiAnswer.push(
				<React.Fragment key={index}>
					<span className={el('pill-emoji')}>
						{emojiElement} {t(answer.label)}
					</span>
					{hasMore && ', '}
				</React.Fragment>,
			);
		});
		return <>{multiSelectEmojiAnswer}</>;
	};

	const transformQuestionIdToHtml = (labelString) => {
		if (labelString.includes('position') && labelString.includes('rank')) {
			return labelString.replace(pipingUtilities.PIPING_REGEX_WITH_RANK, (match, questionId) => {
				const regex = /rank:\s*(\d+)/;
				const rankMatch = labelString.match(regex);
				const rankNumber = rankMatch[1];
				return `<${pipedQuestionElementName} pipedqid=${questionId} logic=${
					match?.includes('not-selected') ? 'not-selected' : 'selected'
				} position=${
					match?.includes('top') ? 'top' : 'bottom'
				} rank=${rankNumber} />$1</${pipedQuestionElementName}>`;
			});
		}
		return labelString.replace(
			pipingUtilities.PIPING_REGEX,
			(match, questionId) =>
				`<${pipedQuestionElementName} pipedqid=${questionId} logic=${
					match?.includes('not-selected') ? 'not-selected' : 'selected'
				}/>$1</${pipedQuestionElementName}>`,
		);
	};

	// Single and Multi Select: Get the answer to the piped question by matching the answerId(s) to the options of all questions
	const getPipedQuestionAnswer = (pipedQid, isNotSelectedLogic, position = undefined, rank = undefined) => {
		let answer = {};
		if (!answers[pipedQid]) return answer;
		const selectedAnswerId = answers[pipedQid].value;
		const unselectedAnswers = (answers[pipedQid]?.availableOptions || []).filter((element) =>
			answers[pipedQid]?.value?.some
				? !answers[pipedQid]?.value?.some((ans) => ans === element)
				: answers[pipedQid]?.value !== element,
		);
		const isRankedQuestion = !!(position && rank);
		const isMultiSelectAnswer = isNotSelectedLogic
			? Array.isArray(unselectedAnswers)
			: Array.isArray(selectedAnswerId);
		const answerId = isNotSelectedLogic ? unselectedAnswers : selectedAnswerId;
		if (isMultiSelectAnswer && !isRankedQuestion) {
			answer = [];
			study.sections.forEach((section) => {
				if (!section.questions) return;
				section.questions.forEach((q) => {
					const foundAnswers = q.options.filter((option) => answerId.includes(option?.id?.toString()));
					if (foundAnswers) answer = answer.length ? answer.concat(foundAnswers) : foundAnswers;
				});
			});
		} else if (isRankedQuestion) {
			answer = [];
			const pipedOptionIds = position === 'top' ? selectedAnswerId.slice(0, rank) : selectedAnswerId.slice(-rank);
			study.sections.forEach((section) => {
				if (!section.questions) return;
				section.questions.forEach((q) => {
					q?.options?.forEach((op) => {
						if (pipedOptionIds.includes(op?.id)) {
							answer.push(op);
						}
					});
				});
			});
		} else {
			study.sections.forEach((section) => {
				if (!section.questions) return;
				section.questions.forEach((q) => {
					const foundAnswer = q.options.find((option) => option?.id?.toString() === answerId);
					if (foundAnswer) answer = foundAnswer;
				});
			});
		}
		return answer;
	};

	// Returns the Question object corresponding with the pipedQid
	const getPipedQuestion = (pipedQid, productId = null) => {
		let question = {};
		if (productId) {
			if (!answers[`${pipedQid}-${productId}`]) return;
		} else if (!answers[pipedQid]) return;
		study.sections.forEach((section) => {
			if (!section.questions) return;
			section.questions.forEach((q) => {
				if (q?.id?.toString() === pipedQid) question = q;
			});
		});
		return question;
	};

	const getEmptyPill = () => <span className={el('empty-state')} />;

	const getHtmlLabel = () => {
		if (!pipingUtilities.hasPipedQuestion(label))
			return parse(label, {
				replace: (node) => {
					node['aria-live'] = 'assertive';
					return node;
				},
			});

		// transformQuestionIdToHtml() converts occurences of {questionId:xx} in the label into HTML strings
		// parse transforms the full HTML string label into JSX
		// replace is a callback which replaces the HTML string inserted by transformQuestionIdToHtml() with the appropriate JSX
		return parse(transformQuestionIdToHtml(label), {
			// eslint-disable-next-line
			replace: domNode => {
				if (domNode.type === 'tag' && domNode.name === pipedQuestionElementName) {
					const pipedQid = domNode?.attribs?.pipedqid;
					const logic = domNode?.attribs?.logic;
					const position = domNode?.attribs?.position;
					const rank = domNode?.attribs?.rank;
					const isNotSelectedLogic = logic?.includes('not-selected');
					if (pipedQid !== 'invalid') {
						const extendedPipedQid = `${pipedQid}-${productId}`; // Idea Split Question IDs are in the form of {questionId}-{productId}
						const answerObj = answers[extendedPipedQid] || answers[pipedQid];
						const unselectedAnswers = (answerObj?.availableOptions || []).filter((element) =>
							answerObj?.value?.some
								? !answerObj?.value?.some((ans) => ans === element)
								: answerObj?.value !== element,
						);
						if ((!answerObj || answerObj?.value?.length === 0) && !isNotSelectedLogic)
							return getEmptyPill();
						if ((!answerObj || unselectedAnswers?.length === 0) && isNotSelectedLogic)
							return getEmptyPill();
						const isEmojiAnswer = answerObj && answerObj.type === 'emoji';
						const isRankedAnswer = answerObj?.type === 'ranked';
						const isMultiSelectAnswer = isNotSelectedLogic
							? Array.isArray(unselectedAnswers)
							: Array.isArray(answerObj?.value);
						const pipedQuestionAnswer = getPipedQuestionAnswer(
							answers[extendedPipedQid] ? extendedPipedQid : pipedQid,
							isNotSelectedLogic,
							position,
							rank,
						);
						const pipedQuestion = productId
							? getPipedQuestion(pipedQid, productId)
							: getPipedQuestion(pipedQid);
						// SingleSelect
						if (!isEmojiAnswer && !isMultiSelectAnswer) {
							return renderSingleSelect(pipedQuestion, pipedQuestionAnswer);
						} // MultiSelect
						if (!isEmojiAnswer && isMultiSelectAnswer) {
							return renderMultiSelect(pipedQuestion, pipedQuestionAnswer);
						} // SingleSelect Emoji
						if (isEmojiAnswer && !isMultiSelectAnswer) {
							return renderSingleSelectEmoji(pipedQuestionAnswer);
						} // MultiSelect Emoji
						if (isEmojiAnswer && isMultiSelectAnswer) {
							return renderMultiSelectEmoji(pipedQuestionAnswer);
						}
					}

					return <></>;
				}
			},
		});
	};

	useEffect(() => {
		if (labelRef.current) {
			labelRef.current.focus();
		}
	}, []);

	return (
		<div className={className} ref={labelRef} tabIndex="-1" aria-live="polite" aria-atomic="true">
			{getHtmlLabel()}
		</div>
	);
};

QuestionLabel.propTypes = {
	t: PropTypes.func,
	study: PropTypes.object,
	label: PropTypes.string,
	answers: PropTypes.object,
	setAssetPreviewModalUrl: PropTypes.func,
	productId: PropTypes.any,
};

export default withTranslation('main')(QuestionLabel);
