// packages
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';

// helpers
import { getEmoji } from 'src/components/helpers/Emoji';

// utilities
import cn from 'src/utilities/bem-cn';

// styles
import './styles.scss';

const className = 'emoji-button';
const el = (name, mod) => cn(className, name, mod);

const animationVariants = {
	hide: {
		y: 15,
		opacity: 0,
		scale: 0.5,
		transition: {
			duration: 0.2,
		},
	},
	show: {
		y: 0,
		opacity: 1,
		scale: 1,
		transition: {
			duration: 0.2,
		},
	},
};

const EmojiButton = ({ label, isChecked, showTooltip }) => {
	const { t } = useTranslation('main');

	return (
		<div className={el('', isChecked && 'is-checked')}>
			<motion.div
				className={el('tooltip')}
				key={`tooltip-animation-${label}`}
				initial={false}
				animate={showTooltip ? 'show' : 'hide'}
				variants={animationVariants}
			>
				<span className={`tooltiptext ${label}`}>{t(label)}</span>
			</motion.div>
			<img src={getEmoji(label)} alt={t(label)} />
		</div>
	);
};

EmojiButton.propTypes = {
	label: PropTypes.string.isRequired,
	isChecked: PropTypes.bool,
	showTooltip: PropTypes.bool,
};

export default EmojiButton;
