import { useGesture } from '@use-gesture/react';
import React, { useEffect, useState, useRef, useMemo } from 'react';
import * as misc from 'src/utilities/misc';
import { isString } from 'lodash';
import { useTranslation } from 'react-i18next';
import ProductDataUtil from 'src/utilities/product-data-util.js';
import Loader from 'src/components/elements/Loader';
import { Tooltip } from 'react-tippy';
import useIsKeyboardNavigation from 'src/utilities/hooks/useIsKeyboardNavigation';
import ProductCardAccessibilityWrapper from 'src/components/shared/ProductCardAccessibilityWrapper';
import { HelperMessages, CLICK_LIMIT_TYPES } from './helper';
import cards from '@upsiide/product-cards';
import './styles.scss';

const mobileBPWidth = 356;
const className = 'question-heatmap';
const gestureSwipeDownBuffer = 10;
const scaleUpperBound = 4;

const dragConfigOptions = {
	swipe: {
		/**
		 * Distance needed in px to register as a swipe,
		 * Default: 50 https://use-gesture.netlify.app/docs/options/#swipedistance
		 * */
		distance: 25,
		/**
		 * Velocity need in px/s to register as a swipe,
		 * Default: 0.5
		 * */
		velocity: 0.75,
	},
	/**
	 * Threshold needed in px to register movement start.
	 * Default: 0
	 */
	threshold: 10,
};

const STUDIES_AFFECTED_WITH_ACCESSIBILITY_BUG = [23716, 23627, 23611, 23558, 23547, 23515, 23507, 23501, 22931, 23329]

const Heatmap = ({ studyId, currentSection, question, onChange, productOrder }) => {
	const allowMultipleClicks = misc.getQuestionSetting(question, 'multi-click') === 'true' || true;
	const clickLimitType = misc.getQuestionSetting(question, 'click-limit-type') || 'unlimited';
	const clickLimit = misc.getQuestionSetting(question, 'click-limit');
	const isOptional = misc.getQuestionSetting(question, 'optional') === 'true';
	const commentsOptional = misc.getQuestionSetting(question, 'optional-comments') === 'true' || false;
	const showImageOnly = misc.getQuestionSetting(question, 'show-image-only') === 'true' || false;
	const [timeBetweenClicks, setTimeBetweenClicks] = useState(new Date());
	const [loading, setLoading] = useState(true);
	const [points, _setPoints] = useState([]);
	const imageRef = useRef(null);
	const pointsRef = useRef(points);
	const setPoints = (data) => {
		pointsRef.current = data;
		_setPoints(data);
	};
	const { t } = useTranslation('main');

	const isKeyboardNavigation = useIsKeyboardNavigation();

	const draggingImage = useRef(false);

	const [heatmapPosition, setHeatmapPosition] = useState([0, 0]);
	const [heatmapScale, setHeatmapScale] = useState(1);
	const [comment, _setComment] = useState(null);
	const commentRef = useRef(comment);
	const setComment = (data) => {
		commentRef.current = data;
		_setComment(data);
	};

	const [viewOnlyComment, _setViewOnlyComment] = useState(null);
	const viewOnlyCommentRef = useRef(viewOnlyComment);
	const setViewOnlyComment = (data) => {
		viewOnlyCommentRef.current = data;
		_setViewOnlyComment(data);
	};

	const [uniquePointId, setUniquePointId] = useState(0);

	const [cancelTransition, setCancelTransition] = useState(true);

	useEffect(() => {
		const pageContainer = document.querySelector('.scroll-container');
		if (pageContainer) {
			pageContainer.style.scrollBehavior = 'auto';
			pageContainer.scrollTo(0, 0);
		}
		// Reset scroll container
		const scrollContainer = document.querySelector(`[class*='scroll-container']`);
		if (scrollContainer) {
			scrollContainer.classList.remove('scrolled-to-bottom');
			scrollContainer.classList.remove('remove-scroll-indicator');
		}

		document.addEventListener('click', handleClickOutside, true);
		const isMobileDevice = document.querySelector('.ios-mobile') || document.querySelector('.android-mobile');
		if (!isMobileDevice) {
			document.getElementById('image-container-ref').addEventListener(
				'wheel',
				(e) => {
					if (e.wheelDeltaY % 1 !== 0) {
						e.preventDefault();
					}
				},
				{ passive: false },
			);
		}
		return () => {
			document.removeEventListener('click', handleClickOutside, true);
		};
	}, []);

	const checkInvalidComments = (value) => {
		// TODO: Check if comments are required first
		if (commentsOptional) return false;

		if (value !== '') return false;
		return true;
	};

	const checkIgnoreClick = (event, checkClassName) => {
		const targetClassName =
			event && event.target && !!event.target.className && isString(event.target.className)
				? event.target.className
				: '';
		const commentElement = document.querySelector('.question-heatmap__image-click-point--comment');
		const checkElement = document.querySelector(checkClassName);
		if (
			checkElement?.contains(event.target) ||
			targetClassName.includes('question-heatmap__image-click-point') ||
			commentElement?.contains(event.target)
		) {
			return true;
		}
		return false;
	};

	const checkClickLimit = () => {
		if (allowMultipleClicks === false && points.length >= 1) {
			return true;
		}

		return (
			(clickLimitType === CLICK_LIMIT_TYPES.EXACTLY || clickLimitType === CLICK_LIMIT_TYPES.RANGE) &&
			points.length >= parseInt(`${clickLimit}`)
		);
	};

	const submitNewPoint = (event) => {
		if (event && event.stopPropagation && event.preventDefault) {
			event.stopPropagation();
			event.preventDefault();
		}
		if (!checkInvalidComments(comment?.comment)) {
			const updatedPoints = pointsRef.current.filter((p) => p?.id !== comment?.id);
			updatedPoints.push({
				...comment,
				comment: comment?.comment,
			});
			setPoints(updatedPoints);
			
			onChange(
				updatedPoints.map((p) => ({
					xCoordinate: p.left,
					yCoordinate: p.top,
					comment: p.comment,
					timeBetweenClicks: p.timeBetweenClicks / 1000,
				})),
				'heatmap',
			);
			setComment(null);
		}
	};

	const cancelNewPoint = (event) => {
		if (event && event.stopPropagation && event.preventDefault) {
			event.stopPropagation();
			event.preventDefault();
		}
		setComment(null);
		const updatedPoints = [...pointsRef.current.splice(0, pointsRef.current.length - 1)];
		setPoints(updatedPoints);
		onChange(
			updatedPoints.map((p) => ({
				xCoordinate: p.left,
				yCoordinate: p.top,
				comment: p.comment,
			})),
			'heatmap',
		);
	};

	const deletePoint = () => {
		setViewOnlyComment(null);
		const updatedPoints = points.filter((p) => p?.id !== viewOnlyComment?.id);
		setPoints(updatedPoints);
		onChange(
			updatedPoints.map((p) => ({
				xCoordinate: p.left,
				yCoordinate: p.top,
				comment: p.comment,
			})),
			'heatmap',
		);
	};

	const resetPoints = () => {
		setPoints([]);
		onChange([], 'heatmap');
		setComment(null);
		setViewOnlyComment(null);
	};

	const handleClickOutside = (event) => {
		if (checkIgnoreClick(event, '.question-heatmap__image-container')) {
			return;
		}

		// If a comment is opened while clicking and comments are required
		if (commentRef?.current !== null && !commentsOptional) {
			cancelNewPoint();
			return;
		}

		if (commentsOptional) {
			setComment(null);
		}

		if (viewOnlyCommentRef.current !== null) {
			setViewOnlyComment(null);
		}
	};

	const handleClick = (event) => {
		const targetClassName =
			event && event.target && !!event.target.className && isString(event.target.className)
				? event.target.className
				: '';
		event.stopPropagation();
		event.preventDefault();
		const imageContainer = document.getElementById('image-container-ref');

		// If a view only comment is opened while clicking
		if (
			(imageContainer.contains(event.target) || targetClassName.includes('question-heatmap__image-container')) &&
			viewOnlyComment
		) {
			setViewOnlyComment(false);
			return;
		}

		if (
			(imageContainer.contains(event.target) || targetClassName.includes('question-heatmap__image-container')) &&
			comment !== null &&
			commentsOptional
		) {
			submitNewPoint(event);
			return;
		}

		// If a comment is opened while clicking and comments are required
		if (
			!targetClassName.includes('question-heatmap__image-click-point--comment-input') &&
			(imageContainer?.contains(event.target) || targetClassName.includes('question-heatmap__image-container')) &&
			comment !== null
		) {
			cancelNewPoint(event);
			return;
		}

		if (checkClickLimit()) {
			return;
		}

		if (checkIgnoreClick(event)) {
			return;
		}

		const {
			top: imageTop,
			left: imageLeft,
			width: imageWidth,
			height: imageHeight,
		} = imageContainer.getBoundingClientRect();
		const scrollOffset = imageContainer.scrollTop;
		const xPercentage = 100 * ((event.clientX - imageLeft) / imageWidth);
		const yPercentage = 100 * ((event.clientY + scrollOffset - imageTop) / imageHeight);

		const currentTime = new Date();
		const timeSinceLastClicked = currentTime.getTime() - timeBetweenClicks.getTime();
		setTimeBetweenClicks(currentTime);
		const newPoint = {
			id: uniquePointId,
			left: xPercentage,
			top: yPercentage,
			comment: '',
			timeBetweenClicks: timeSinceLastClicked,
		};
		if (commentsOptional === false) setComment(newPoint);
		setPoints([...points, newPoint]);
		if (commentsOptional) {
			onChange(
				pointsRef.current.map((p) => ({
					xCoordinate: p.left,
					yCoordinate: p.top,
					comment: p.comment,
					timeBetweenClicks: p.timeBetweenClicks / 1000,
				})),
				'heatmap',
			);
		}

		setUniquePointId((prev) => prev + 1);
		if (commentsOptional === false) {
			setTimeout(() => {
				const inputField = document.getElementById('comment-input-field');
				if (inputField) {
					inputField.focus();
				}
			}, 300);
		}
	};

	const getCommentStyle = (point) => {
		const imageContainer = document.getElementById('image-container-gesture-container-ref');
		let styleLeft = `${point.left}%`;
		let styleTop = `${point.top}%`;

		const { width: imageWidth, height: imageHeight, bottom: imageBottom } = imageContainer.getBoundingClientRect();
		const scrollOffset = imageContainer.scrollTop;

		const maxCommentHeight = 80;
		let maxCommentWidth = window.innerWidth <= mobileBPWidth ? 230 : 300;
		if (isInSplitsSection()) {
			maxCommentWidth = window.innerWidth <= mobileBPWidth ? 230 : 272;
		}
		const paddingBuffer = 8;
		const commentWidth = maxCommentWidth + paddingBuffer;
		const commentHeight = maxCommentHeight + paddingBuffer;
		const commentLeft = imageWidth * (point.left / 100);
		const commentTop = imageHeight * (point.top / 100);
		const commentRight = commentLeft + commentWidth;
		const commentBottom = commentTop + commentHeight;

		if (commentRight > imageWidth) {
			const difference = commentRight - imageWidth;
			styleLeft = `calc(${point.left}% - ${difference}px`;
		}
		if (commentBottom > imageHeight + scrollOffset) {
			const difference = commentBottom - (imageHeight + scrollOffset);
			styleTop = `calc(${point.top}% - ${difference}px`;
		}

		return { left: styleLeft, top: styleTop };
	};

	const renderProduct = () => {
		const { products, settings } = currentSection;
		const ideaScreenLayout = settings.find((s) => s.label === 'idea_screen_layout').value || 'invalid_layout';
		const imageCropping = settings.find((s) => s.label === 'image_cropping') || 'fit';
		const imageSize = settings.find((s) => s.label === 'image_size') || 'medium';
		const stackCardData = ProductDataUtil.getStackProduct(
			products[productOrder],
			ideaScreenLayout,
			imageSize,
			imageCropping,
		);

		let isVideo = false;
		if (
			products[productOrder]?.fieldOneType === 'asset' &&
			products[productOrder]?.fieldOne[0]?.mediaType === 'video'
		) {
			isVideo = true;
		}

		if (
			products[productOrder]?.fieldTwoType === 'asset' &&
			products[productOrder]?.fieldTwo[0]?.mediaType === 'video'
		) {
			isVideo = true;
		}

		if (
			products[productOrder]?.fieldThreeType === 'asset' &&
			products[productOrder]?.fieldThree[0]?.mediaType === 'video'
		) {
			isVideo = true;
		}

		if (ideaScreenLayout === 'sideBySide') stackCardData.twoColumn = true;

		const getImageFromVariations = (variations) => {
			if (variations?.find((v) => v.type === 'large')) {
				return variations.find((v) => v.type === 'large').location;
			}
			if (variations?.find((v) => v.type === 'full')) {
				return variations.find((v) => v.type === 'full').location;
			}
			if (variations?.find((v) => v.type === 'medium')) {
				return variations.find((v) => v.type === 'medium').location;
			}
		};

		if (showImageOnly) {
			let imgTag = (
				<img
					className={`${className}__img-only`}
					alt="Product"
					onLoad={() => {
						setLoading(false);
					}}
				/>
			);
			if (products[productOrder]?.fieldOneType === 'asset') {
				imgTag = (
					<img
						className={`${className}__img-only`}
						alt="Product"
						onLoad={() => {
							setLoading(false);
						}}
						src={
							getImageFromVariations(products[productOrder]?.fieldOne[0]?.variations) ||
							products[productOrder]?.fieldOne[0]?.url
						}
					/>
				);
			} else if (products[productOrder]?.fieldTwoType === 'asset') {
				imgTag = (
					<img
						className={`${className}__img-only`}
						alt="Product"
						onLoad={() => {
							setLoading(false);
						}}
						src={
							getImageFromVariations(products[productOrder]?.fieldTwo[0]?.variations) ||
							products[productOrder]?.fieldTwo[0]?.url
						}
					/>
				);
			} else if (products[productOrder]?.fieldThreeType === 'asset') {
				imgTag = (
					<img
						className={`${className}__img-only`}
						alt="Product"
						onLoad={() => {
							setLoading(false);
						}}
						src={
							getImageFromVariations(products[productOrder]?.fieldThree[0]?.variations) ||
							products[productOrder]?.fieldThree[0]?.url
						}
					/>
				);
			}
			return (
				<div className={`${className}__product-view image-only`}>
					{loading && <Loader centered />}
					{imgTag}
				</div>
			);
		}

		const showAccessibilityBugView = useMemo(() => {
			if (!studyId) return 
			if (!STUDIES_AFFECTED_WITH_ACCESSIBILITY_BUG?.length) return 
			return STUDIES_AFFECTED_WITH_ACCESSIBILITY_BUG.includes(studyId)
		}, [studyId, STUDIES_AFFECTED_WITH_ACCESSIBILITY_BUG])

		// * We're using the stack card because it is used across the dashboard
		// * and has already been modified to accommodate the 'idea_screen_layout' setting (e.g. text only, image only, no title, title top, title bottom, side by side)
		return (
			<div className={`${className}__product-view${isVideo ? ' has-video' : ''}`}>
				<ProductCardAccessibilityWrapper showAccessibilityBugView={showAccessibilityBugView} >
					<cards.StackCard product={stackCardData} interactionsDisabled monadicSplitSection />
				</ProductCardAccessibilityWrapper>
			</div>
		);
	};

	const isInSplitsSection = () => currentSection.type === 'monadic_split';

	const updateQuestionContainerAlignment = () => {
		setTimeout(() => {
			const questionContainer = document.querySelector('.questions-container');
			const questionRef = document.querySelector('.questions-container__questions');
			if (questionRef?.clientHeight > window.innerHeight) {
				questionContainer.style.justifyContent = 'flex-start';
			}
		});
	};

	const handleHeatMapDrag = ({ event, ...drag }) => {
		event.stopPropagation();
		event.preventDefault();
		if (drag.pinching || heatmapScale <= 1) {
			return;
		}

		if (drag.last) {
			setTimeout(() => {
				draggingImage.current = false;
			}, 300);
		} else {
			draggingImage.current = true;
		}
		const x = heatmapScale <= 1 && drag.last ? 0 : drag.offset[0];
		const y = heatmapScale <= 1 && drag.last ? 0 : drag.offset[1];
		setCancelTransition(!drag.last);
		setHeatmapPosition([x, y]);
	};

	const handleHeatMapPinch = ({ event, ...pinch }) => {
		const [scale, _] = pinch.offset;
		const { top, left, width, height } = imageRef.current.getBoundingClientRect();
		if (pinch.last) {
			let updatedX = heatmapPosition[0];
			let updatedY = heatmapPosition[1];
			if (left < -(width / 2)) {
				updatedX = 0;
			}
			if (left > width / 2) {
				updatedX = 0;
			}
			if (top < -(height / 2)) {
				updatedY = 0;
			}
			if (top > height / 2) {
				updatedY = 0;
			}
			setHeatmapPosition([updatedX, updatedY]);
		}

		if (scale <= 1 && pinch.last) {
			setHeatmapPosition([0, 0]);
		}
		setHeatmapScale(scale);
	};

	const handleHeatmapGestures = useGesture(
		{
			onDrag: (event) => {
				handleHeatMapDrag(event);
			},
			onPinch: (event) => {
				handleHeatMapPinch(event);
			},
		},
		{
			drag: {
				...dragConfigOptions,
				// preventScroll: heatmapScale > 1 ? 0 : false,
				from: () => [heatmapPosition[0], heatmapPosition[1]],
				bounds: () => {
					const { width, height } = imageRef.current.getBoundingClientRect();
					return {
						left: -width / 2,
						right: width / 2,
						top: -height / 2,
						bottom: height / 2,
					};
				},
				rubberband: true,
			},
			pinch: {
				scaleBounds: { min: 1, max: scaleUpperBound },
				rubberband: true,
			},
		},
	);

	return (
		<div className={className}>
			<div className={`${className}__title`}>
				{HelperMessages(
					allowMultipleClicks,
					clickLimitType,
					clickLimit,
					commentsOptional,
					!showImageOnly && isInSplitsSection(),
				)}
				<div
					className={`${className}__reset-button${isInSplitsSection() ? ' splits' : ''}${
						points?.length > 0 ? ' active' : ''
					}`}
				>
					<Tooltip
						animation="shift"
						animationFill={false}
						arrow
						interactive
						// style={{ top: '-15px', left: '-7px' }}
						theme="light"
						position="left"
						trigger="mouseenter focus"
						html={t('remove')}
					>
						<svg
							onClick={() => resetPoints()}
							width="22"
							height="22"
							viewBox="0 0 22 22"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M19.8894 11C19.8894 15.4183 16.3077 19 11.8894 19C10.2471 19.0054 8.64392 18.499 7.30274 17.5511C7.19558 17.4739 7.12742 17.3538 7.11608 17.2222C7.10547 17.0941 7.15085 16.9677 7.24052 16.8756L7.88052 16.2267C8.03076 16.078 8.26492 16.0557 8.44052 16.1733C9.45936 16.86 10.6607 17.2254 11.8894 17.2222C15.3258 17.2222 18.1116 14.4364 18.1116 11C18.1116 7.56356 15.3258 4.77778 11.8894 4.77778C8.45297 4.77778 5.66719 7.56356 5.66719 11H7.76496C7.88548 10.9983 8.00134 11.0465 8.08496 11.1333L8.26274 11.3111C8.34688 11.3946 8.39421 11.5082 8.39421 11.6267C8.39421 11.7452 8.34688 11.8588 8.26274 11.9422L5.12496 15.0889C4.94913 15.2594 4.66969 15.2594 4.49385 15.0889L1.35608 11.9422C1.27194 11.8588 1.22461 11.7452 1.22461 11.6267C1.22461 11.5082 1.27194 11.3946 1.35608 11.3111L1.53385 11.1333C1.61748 11.0465 1.73333 10.9983 1.85385 11H3.88941C3.88941 6.58172 7.47113 3 11.8894 3C16.3077 3 19.8894 6.58172 19.8894 11Z"
								fill="#BABABA"
							/>
						</svg>
					</Tooltip>
				</div>
			</div>
			<div
				id="image-container-gesture-container-ref"
				className={`${className}__image-container-gesture-container${isInSplitsSection() ? ' splits' : ''}`}
			>
				<div
					id="image-container-ref"
					className={`${className}__image-container${checkClickLimit() ? ' click-limit' : ''}${
						isInSplitsSection() ? ' splits' : ''
					}${showImageOnly ? ' image-only' : ''}`}
					onClick={(e) => {
						if (!draggingImage.current) {
							handleClick(e);
						}
					}}
					{...handleHeatmapGestures()}
					style={{
						transform: `translate3d(${heatmapPosition[0]}px, ${heatmapPosition[1]}px, 0) scale(${heatmapScale})`,
						transition: cancelTransition ? 'none' : null,
						touchAction: `${heatmapScale > 1 ? 'none' : ''}`,
					}}
					ref={imageRef}
				>
					{currentSection.type !== 'monadic_split' && (
						<>
							{loading && <Loader centered />}
							<img
								className={`${className}__image`}
								src={`${question?.asset?.url}`}
								alt="Product"
								onLoad={() => {
									setLoading(false);
									updateQuestionContainerAlignment();
								}}
							/>
						</>
					)}
					{isInSplitsSection() && renderProduct()}
					{points &&
						points.map(
							(point) =>
								(point.comment || commentsOptional) && (
									<div
										onClick={() => {
											if (commentsOptional === true) return;
											setViewOnlyComment(point);

											// If a comment is opened while clicking
											if (comment) {
												setComment(null);
												setPoints([...points.splice(0, points.length - 1)]);
											}
										}}
										key={point.id}
										style={{
											left: `${point.left}%`,
											top: `${point.top}%`,
											transform: `translateX(-12px) translateY(-12px) scale(${1 / heatmapScale})`,
										}}
										className={`${className}__image-click-point${
											commentsOptional === true ? ' no-comments' : ''
										}`}
									/>
								),
						)}
				</div>
				{comment && (
					<div className={`${className}__image-click-point--comment`} style={getCommentStyle(comment)}>
						<input
							autoFocus
							id="comment-input-field"
							type="text"
							autoComplete="off"
							className={`${className}__image-click-point--comment-input`}
							onKeyDown={(event) => {
								if (event.key === 'Enter' || event.which === 13 || event.keyCode === 13) {
									event.preventDefault();
									submitNewPoint(event);
								} else if (event.key === 'Escape' || event.which === 27 || event.keyCode === 27) {
									cancelNewPoint(event);
								}
							}}
							onInput={(event) => {
								const updatedComment = `${event.target.value}`;
								setComment({
									...comment,
									comment: updatedComment,
								});
								if (isOptional) {
									const currentPoints = pointsRef.current.map((point) => {
										if (point.id === comment.id) {
											if (updatedComment === '') {
												return null;
											}
											return {
												xCoordinate: point.left,
												yCoordinate: point.top,
												comment: updatedComment,
												timeBetweenClicks: point.timeBetweenClicks / 1000,
											};
										}
										return {
											xCoordinate: point.left,
											yCoordinate: point.top,
											comment: point.comment,
											timeBetweenClicks: point.timeBetweenClicks / 1000,
										};
									});
									onChange(
										currentPoints.filter((p) => p !== null),
										'heatmap',
									);
								}
							}}
							placeholder={t('heatmap-type-comment')}
							style={{
								...(!isKeyboardNavigation && { outline: 'none' }),
							}}
						/>
						<div className={`${className}__image-click-point--comment-divider`} />
						<div
							className={`${className}__image-click-point--comment-checkmark-container ${
								!checkInvalidComments(comment?.comment) ? ' valid' : ''
							}`}
						>
							<button
								type="button"
								onClick={(event) => {
									cancelNewPoint(event);
								}}
								className={`${className}__image-click-point--comment-cancel`}
							>
								{t('cancel')}
							</button>
							<svg
								onClick={(event) => {
									submitNewPoint(event);
								}}
								width="24"
								height="24"
								viewBox="0 0 24 25"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<rect width="24" height="24" rx="12" fill="#BABABA" />
								<path
									d="M5.33398 12.3326V11.6659C5.33398 11.4818 5.48322 11.3326 5.66732 11.3326H16.4473L13.4807 8.37258C13.4175 8.30999 13.3821 8.2248 13.3821 8.13592C13.3821 8.04704 13.4175 7.96184 13.4807 7.89925L13.954 7.43258C14.0166 7.36948 14.1018 7.33398 14.1907 7.33398C14.2795 7.33398 14.3647 7.36948 14.4273 7.43258L18.5207 11.5193C18.6144 11.6129 18.6672 11.74 18.6673 11.8726V12.1259C18.6658 12.2582 18.6132 12.3848 18.5207 12.4793L14.4273 16.5659C14.3647 16.629 14.2795 16.6645 14.1907 16.6645C14.1018 16.6645 14.0166 16.629 13.954 16.5659L13.4807 16.0926C13.4179 16.0311 13.3826 15.947 13.3826 15.8593C13.3826 15.7715 13.4179 15.6874 13.4807 15.6259L16.4473 12.6659H5.66732C5.48322 12.6659 5.33398 12.5167 5.33398 12.3326Z"
									fill="white"
								/>
							</svg>
						</div>
					</div>
				)}
				{viewOnlyComment && (
					<div
						className={`${className}__image-click-point--comment view-only`}
						style={getCommentStyle(viewOnlyComment)}
					>
						<div className={`${className}__image-click-point--comment-input`}>
							{viewOnlyComment?.comment}
						</div>
						<div className={`${className}__image-click-point--comment-divider`} />
						<div className={`${className}__image-click-point--comment-delete-container`}>
							<svg
								onClick={() => {
									deletePoint();
								}}
								width="16"
								height="16"
								viewBox="0 0 12 15"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M0.676056 4.20316H1.53803V12.0168C1.53803 12.5871 1.75171 13.1341 2.13207 13.5374C2.51242 13.9407 3.02829 14.1673 3.5662 14.1673H8.4338C8.97171 14.1673 9.48758 13.9407 9.86794 13.5374C10.2483 13.1341 10.462 12.5871 10.462 12.0168V4.20316H11.3239C11.5032 4.20316 11.6752 4.12764 11.802 3.9932C11.9288 3.85877 12 3.67643 12 3.48631C12 3.2962 11.9288 3.11386 11.802 2.97943C11.6752 2.84499 11.5032 2.76947 11.3239 2.76947H8.31549V2.26768C8.31549 1.88744 8.17304 1.52277 7.91947 1.2539C7.6659 0.985034 7.32198 0.833984 6.96338 0.833984H5.03662C4.67802 0.833984 4.3341 0.985034 4.08053 1.2539C3.82696 1.52277 3.68451 1.88744 3.68451 2.26768V2.76947H0.676056C0.496755 2.76947 0.324797 2.84499 0.198012 2.97943C0.0712271 3.11386 0 3.2962 0 3.48631C0 3.67643 0.0712271 3.85877 0.198012 3.9932C0.324797 4.12764 0.496755 4.20316 0.676056 4.20316ZM9.10986 12.0168C9.10986 12.2069 9.03863 12.3892 8.91185 12.5237C8.78506 12.6581 8.6131 12.7336 8.4338 12.7336H3.5662C3.3869 12.7336 3.21494 12.6581 3.08815 12.5237C2.96137 12.3892 2.89014 12.2069 2.89014 12.0168V4.20316H9.10986V12.0168ZM5.05352 2.24976H6.96338V2.75155H5.03662L5.05352 2.24976Z"
									fill="#666666"
								/>
								<path
									d="M7.26785 11.8019C7.44715 11.8019 7.61911 11.7264 7.7459 11.5919C7.87268 11.4575 7.94391 11.2752 7.94391 11.085V5.72661C7.94391 5.53649 7.87268 5.35416 7.7459 5.21973C7.61911 5.08529 7.44715 5.00977 7.26785 5.00977C7.08855 5.00977 6.91659 5.08529 6.78981 5.21973C6.66302 5.35416 6.5918 5.53649 6.5918 5.72661V11.085C6.5918 11.2752 6.66302 11.4575 6.78981 11.5919C6.91659 11.7264 7.08855 11.8019 7.26785 11.8019Z"
									fill="#666666"
								/>
								<path
									d="M4.7327 11.8019C4.912 11.8019 5.08396 11.7264 5.21074 11.5919C5.33753 11.4575 5.40875 11.2752 5.40875 11.085V5.72661C5.40875 5.53649 5.33753 5.35416 5.21074 5.21973C5.08396 5.08529 4.912 5.00977 4.7327 5.00977C4.5534 5.00977 4.38144 5.08529 4.25465 5.21973C4.12787 5.35416 4.05664 5.53649 4.05664 5.72661V11.085C4.05664 11.2752 4.12787 11.4575 4.25465 11.5919C4.38144 11.7264 4.5534 11.8019 4.7327 11.8019Z"
									fill="#666666"
								/>
							</svg>
						</div>
					</div>
				)}
			</div>
		</div>
	);
};

export default Heatmap;
