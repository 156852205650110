// packages
import React from 'react';
import PropTypes from 'prop-types';

// hooks
import useIsKeyboardNavigation from 'src/utilities/hooks/useIsKeyboardNavigation';

// styles
import './styles.scss';

const mainClassName = 'questionnaire-input';

const Input = ({ className, value, onChange, ...props }) => {
	// state
	const isKeyboardNavigation = useIsKeyboardNavigation();

	return (
		<input
			{...props}
			className={`${className} ${mainClassName}`}
			value={value || ''}
			onChange={(e) => onChange(e.target.value)}
			style={{
				...(!isKeyboardNavigation && { outline: 'none' }),
			}}
		/>
	);
};

Input.propTypes = {
	className: PropTypes.string,
	value: PropTypes.string,
	onChange: PropTypes.func,
};

export default Input;
