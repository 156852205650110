// packages
import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { motion } from 'framer-motion';

// utilities
import useLogFocusedElement from '../utilities/hooks/useLogFocusedElement';

// components
import Main from './main/pages/Main';
import NotFound from './main/pages/NotFound';

// constants
const posedRoute = {
	enter: {
		x: 0,
		opacity: 1,
		delay: 0,
		transition: {
			duration: 0,
			ease: 'linear',
		},
	},
	exit: {
		opacity: 1,
		transition: {
			duration: 0,
			ease: 'linear',
		},
	},
};

const DomainRoutes = () => {
	useLogFocusedElement(process.env.NODE_ENV === 'development');

	return (
		<Routes>
			{/* Allow either URL based on URL Parameter based */}
			<Route
				path="/study/:studyId/user/:rnid"
				element={
					<motion.div variants={posedRoute} initial="enter">
						<Main />
					</motion.div>
				}
			/>

			<Route
				path="/study/:studyId/*"
				element={
					<motion.div variants={posedRoute} initial="enter">
						<Main />
					</motion.div>
				}
			/>

			<Route
				path="/:uuid/"
				element={
					<motion.div variants={posedRoute} initial="enter">
						<Main />
					</motion.div>
				}
			/>

			<Route
				path="/:uuid/user/:rnid"
				element={
					<motion.div variants={posedRoute} initial="enter">
						<Main />
					</motion.div>
				}
			/>

			{process.env.NODE_ENV === 'development' && (
				<Route
					path="/uidemo/:viewtype/:producttype/:variant"
					element={
						<motion.div variants={posedRoute} initial="enter">
							<Main />
						</motion.div>
					}
				/>
			)}

			<Route path="/*" element={<NotFound />} />
		</Routes>
	);
};
export default DomainRoutes;
