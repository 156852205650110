import React, { useEffect, useState, useRef, forwardRef, useImperativeHandle, cloneElement } from 'react';
import PropTypes from 'prop-types';
import { useGesture } from '@use-gesture/react';
import iconAccept from 'public/images/icon-accept.svg';
import iconReject from 'public/images/icon-reject.svg';
import './styles.scss';

const debounceDelay = 500;
const ideaHeightBottomBuffer = 90;
const clamp = (n, min, max) => Math.max(Math.min(n, max), min);
const ideaGestureDataInitial = {
	xMovement: 0,
	rotate: 0,
	scale: 1,
	opacity: 1,
	transition: '',
	currentIdeaId: null,
	localProductId: null,
	ideaSwiped: null,
	buttonClicked: false,
	draggingIdea: false,
	lastDragMovement: 0,
};
const initialTourMovementPx = 5;

const Stage = forwardRef(({ onInterest, render, renderButtons, data, disableSwipe }, ref) => {
	const [ideaData, setIdeaData] = useState(data);
	const [debounceStartTimer, setDebounceStartTimer] = useState(new Date());
	const [resetDisabledButtons, setResetDisabledButtons] = useState(null);
	const ideaTourRef = useRef({ started: false, finished: false });
	const [ideaTour, setIdeaTour] = useState({ started: false, finished: false });
	const [ideaGestureData, setIdeaGestureData] = useState(ideaGestureDataInitial);
	const [ideaCardHeight, setIdeaCardHeight] = useState(window.innerHeight - ideaHeightBottomBuffer);
	const swipeAnimationTime = 0.75;

	const renderLimit = 3;
	const swipeThreshold = Math.min((window.innerWidth / 3) * 1.1, 300);
	const maxWidth = 600;

	/** Returns the product card styles after a swipe
	 * direction: right: 1, left: -1 */
	const ideaSwipedStyles = (direction) => ({
		...ideaGestureData,
		rotate: direction > 0 ? 25 : -25,
		opacity: 0,
		xMovement: direction > 0 ? Math.min(maxWidth, window.innerWidth) : -Math.min(maxWidth, window.innerWidth),
		transition: `all ${swipeAnimationTime}s ease`,
		ideaSwiped: direction,
		lastDragMovement: direction,
	});

	const dragConfigOptions = {
		swipe: {
			/**
			 * Distance needed in px to register as a swipe,
			 * Default: 50 https://use-gesture.netlify.app/docs/options/#swipedistance
			 * */
			distance: 25,
			/**
			 * Velocity need in px/s to register as a swipe,
			 * Default: 0.5
			 * */
			velocity: 0.75,
		},
		/**
		 * Threshold needed in px to register movement start.
		 * Default: 0
		 */
		threshold: 50,
	};

	const ideaTourAnimation = (id) => {
		const tourTransform = {
			scale: 1,
			opacity: 1,
			rotate: 0,
			transition: 'all 1s ease',
			currentIdeaId: id,
			draggingIdea: true,
		};

		if (!ideaTourRef.current.finished) setIdeaGestureData(tourTransform);
		// Swipe Right Tour
		setTimeout(() => {
			if (!ideaTourRef.current.finished) {
				setIdeaGestureData({
					...tourTransform,
					xMovement: initialTourMovementPx,
				});
			}
		}, 300);
		setTimeout(() => {
			if (!ideaTourRef.current.finished) {
				setIdeaGestureData({
					...tourTransform,
					xMovement: swipeThreshold - 5,
				});
			}
		}, 500);
		setTimeout(() => {
			if (!ideaTourRef.current.finished) {
				setIdeaGestureData({
					...tourTransform,
					xMovement: 0,
				});
			}
		}, 2000);
		// Swipe Left Tour
		setTimeout(() => {
			if (!ideaTourRef.current.finished) {
				setIdeaGestureData({
					...tourTransform,
					xMovement: -initialTourMovementPx,
				});
			}
		}, 3300);
		setTimeout(() => {
			if (!ideaTourRef.current.finished) {
				setIdeaGestureData({
					...tourTransform,
					xMovement: -swipeThreshold + 5,
				});
			}
		}, 3500);
		setTimeout(() => {
			if (!ideaTourRef.current.finished) {
				setIdeaGestureData({
					...tourTransform,
					xMovement: 0,
				});
			}
		}, 5000);
		setTimeout(() => {
			if (!ideaTourRef.current.finished) {
				setIdeaTour({ ...ideaTour, finished: true });
				ideaTourRef.current = { ...ideaTour, finished: true };
				setIdeaGestureData(ideaGestureDataInitial);
			}
		}, 6000);
	};

	useEffect(() => {
		window.addEventListener('resize', updateIdeaHeightCalculations);
		window.addEventListener('orientationchange', updateIdeaHeightCalculations);
		return () => {
			window.removeEventListener('resize', updateIdeaHeightCalculations);
			window.removeEventListener('orientationchange', updateIdeaHeightCalculations);
		};
	}, []);

	// Tour trigger
	useEffect(() => {
		if (ideaData[0]) {
			if (!disableSwipe && !ideaTour.started && !ideaTourRef.current.finished) {
				const { id } = ideaData[0];
				setTimeout(() => {
					if (!ideaTourRef.current.finished) {
						setIdeaTour({ started: true, finished: false });
						ideaTourRef.current = { started: true, finished: false };
						ideaTourAnimation(id);
					}
				});
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [ideaData]);

	useEffect(() => {
		if (data?.length !== ideaData?.length) {
			setIdeaData(data);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data?.length]);

	// After user swiped idea left or right or after reject/accept buttons clicked
	useEffect(() => {
		if (ideaGestureData.ideaSwiped) {
			setTimeout(() => {
				onInterest({
					id: ideaGestureData.currentIdeaId,
					localProductId: ideaGestureData.localProductId,
					interest: ideaGestureData.ideaSwiped > 0,
				});
				setIdeaData((prevState) => prevState?.filter((item) => item.id !== ideaGestureData.currentIdeaId));
				setIdeaGestureData(ideaGestureDataInitial);
				setDebounceStartTimer(new Date());
				setResetDisabledButtons(false);
				setTimeout(() => {
					setResetDisabledButtons(true);
				}, 30);
			}, swipeAnimationTime * 1000);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [ideaGestureData.ideaSwiped]);

	useImperativeHandle(
		ref,
		() => ({
			// ... your methods ...
			updateDisabledButtonResetParent: () => {
				updateDisabledButtonReset();
			},
		}),
		[],
	);

	const updateIdeaHeightCalculations = () => {
		setIdeaCardHeight(window.innerHeight - ideaHeightBottomBuffer);
	};

	const updateDisabledButtonReset = () => {
		setResetDisabledButtons(false);
		setTimeout(() => {
			setResetDisabledButtons(true);
		}, 30);
	};

	const accept = () => {
		const { id } = ideaData[0];
		const { localProductId } = ideaData[0];

		setIdeaGestureData({ ...ideaGestureData, lastDragMovement: 1, buttonClicked: true });
		// Allow re-render to reset product card on the left side then transition to center
		setTimeout(() => {
			const updateIdeaFromDrag = {
				...ideaSwipedStyles(1),
				currentIdeaId: id,
				localProductId,
				buttonClicked: false,
			};
			setIdeaGestureData({ ...updateIdeaFromDrag });
		}, 30);
	};

	const reject = () => {
		const { id } = ideaData[0];
		const { localProductId } = ideaData[0];

		const updateIdeaFromDrag = {
			...ideaSwipedStyles(-1),
			currentIdeaId: id,
			buttonClicked: true,
			localProductId,
		};
		setIdeaGestureData({ ...updateIdeaFromDrag });
	};

	const handleDrag = ({ args: [{ id }], cancel, ...drag }) => {
		if (document.querySelector('.image-zoom-modal-open')) {
			cancel();
			return;
		}
		if (disableSwipe || (ideaTour.started && !ideaTour.finished)) return;
		if (!ideaTour.started && !ideaTour.finished) {
			setIdeaTour({ ...ideaTour, finished: true });
			ideaTourRef.current = { ...ideaTour, finished: true };
		}
		const updatedRotation = clamp((drag.movement[0] / swipeThreshold) * 15, -15, 15);

		const currentIdeaId = ideaGestureData.currentIdeaId === null ? id : ideaGestureData.currentIdeaId;

		let updateIdeaFromDrag = {
			...ideaGestureData,
			scale: 1,
			opacity: clamp(1 / (Math.abs(drag.movement[0]) / 100) + 0.5, 0, 1),
			rotate: drag.last ? 0 : updatedRotation,
			xMovement: drag.last ? 0 : drag.movement[0],
			lastDragMovement: drag.last ? ideaGestureData.lastDragMovement : drag.movement[0],
			transition: drag.last ? 'all 0.5s ease' : '',
			currentIdeaId,
			draggingIdea: !drag.last ? drag.movement[0] : false,
		};

		/** Start Handle Swipe */
		if (drag.swipe[0]) {
			updateIdeaFromDrag = {
				...ideaSwipedStyles(drag.swipe[0]),
				currentIdeaId,
				localProductId: ideaData?.find((idea) => idea.id === currentIdeaId)?.localProductId,
				draggingIdea: false,
			};
		}

		if (drag.last && Math.abs(drag.movement[0]) > swipeThreshold) {
			updateIdeaFromDrag = {
				...ideaSwipedStyles(drag.movement[0] > 0 ? 1 : -1),
				currentIdeaId,
				localProductId: ideaData?.find((idea) => idea.id === currentIdeaId)?.localProductId,
				draggingIdea: false,
			};
		}

		if (drag.last && drag.velocity[0] >= dragConfigOptions.swipe.velocity) {
			updateIdeaFromDrag = {
				...ideaSwipedStyles(drag.direction[0] > 0 ? 1 : -1),
				currentIdeaId,
				localProductId: ideaData?.find((idea) => idea.id === currentIdeaId)?.localProductId,
				draggingIdea: false,
			};
		}
		/** End Handle Swipe */

		setIdeaGestureData({ ...updateIdeaFromDrag });
	};

	// Hook for detecting gesture events: use-gesture/react
	const handleGestures = useGesture(
		{
			onDrag: (event) => {
				const delay = new Date() - debounceStartTimer;
				if (delay < debounceDelay) return;
				handleDrag(event);
			},
		},
		{ drag: { ...dragConfigOptions } },
	);

	const calculateIdeaCardStyles = ({ id, index }) => {
		const { xMovement, rotate, scale, opacity, transition, ideaSwiped } = ideaGestureData;

		// The idea being dragged/has user input (Swipe)
		if (id === ideaGestureData.currentIdeaId) {
			return {
				transform: `translate3d(${xMovement}px, 0px, 0) scale(${scale}) rotate(${rotate}deg)`,
				opacity,
				transition,
				pointerEvents: ideaSwiped ? 'none' : 'auto',
				zIndex: ideaData.length - index,
			};
		}

		if (ideaTour.started && !ideaTour.finished) {
			return {
				opacity: 0.25,
				filter: 'blur(10px)',
				transform: 'scale(0.75)',
			};
		}

		// Idea at the top of stack
		if (index === 0) {
			return {
				opacity: 1,
				transform: `translate3d(0px, 0, 0) scale(1) rotate(0deg)`,
				pointerEvents: 'auto',
				zIndex: ideaData.length - index,
				filter: 'none',
			};
		}

		// Next idea in the stack
		const nextScale = index === 1 ? clamp(Math.abs(xMovement) / swipeThreshold, 0, 1) : 0;
		const nextOpacity = index === 1 ? clamp(Math.abs(xMovement) / swipeThreshold, 0, 0.5) : 0;

		return {
			opacity: ideaSwiped ? 0 : nextOpacity,
			transform: `translate3d(0, 0, 0) scale(${nextScale}) rotate(0deg)`,
			pointerEvents: 'none',
			zIndex: ideaData.length - index,
			filter: 'blur(30px)',
		};
	};
	const calculateSwipeIndicatorStyles = () => {
		const { xMovement, ideaSwiped } = ideaGestureData;
		const startPositionX = 100;
		const endPositionX = 50;
		const inTour = ideaTour.started && !ideaTour.finished;
		const swipeDetected = !!ideaSwiped && ideaSwiped !== 0;

		if (swipeDetected) {
			return {
				top: `calc(${window.innerHeight / 4}px - 25px)`,
				right: ideaSwiped < 0 ? 'auto' : `${endPositionX}px`,
				left: ideaSwiped < 0 ? `${endPositionX}px` : 'auto',
				transform: `scale(1.5)`,
				backgroundColor: ideaSwiped < 0 ? '#FF3C41' : '#28B681',
				opacity: 1,
			};
		}

		let leftTransform = `-${startPositionX}px`;
		let rightTransform = 'auto';
		let opacity = 0;

		if (xMovement !== 0) {
			opacity = clamp(Math.abs(xMovement || 0) / swipeThreshold, 0.5, 1);
			rightTransform =
				xMovement > 0
					? `${clamp(
							endPositionX * (xMovement / swipeThreshold) - endPositionX,
							-startPositionX,
							endPositionX,
					  )}px`
					: 'auto';
			leftTransform =
				xMovement < 0
					? `${clamp(
							endPositionX * (-xMovement / swipeThreshold) - endPositionX,
							-startPositionX,
							endPositionX,
					  )}px`
					: 'auto';
		}

		if (inTour && !!xMovement) {
			leftTransform = xMovement === -initialTourMovementPx ? `-${startPositionX}px` : `${endPositionX}px`;
			rightTransform = xMovement === initialTourMovementPx ? `-${startPositionX}px` : `${endPositionX}px`;
			if (xMovement > 0) {
				leftTransform = 'auto';
			}
			if (xMovement < 0) {
				rightTransform = 'auto';
			}
		}

		return {
			transition: inTour && 'all ease-in-out 1s',
			top: `calc(${window.innerHeight / 4}px - 25px)`,
			right: rightTransform,
			left: xMovement ? leftTransform : `-${startPositionX}px`,
			transform: `scale(${clamp(1 + Math.abs(xMovement) / swipeThreshold, 1, 1.5)})`,
			backgroundColor: xMovement > 0 ? '#28B681' : '#FF3C41',
			opacity: opacity || 0,
		};
	};

	return (
		<>
			<div
				className="product-card-stack"
				style={{
					height: `${ideaCardHeight}px`,
					maxHeight: `${ideaCardHeight}px`,
					minHeight: `${ideaCardHeight}px`,
				}}
			>
				{ideaData?.map(
					({ id, ...rest }, i) =>
						i < renderLimit && (
							<>
								<div
									key={id}
									className={`product-card-item${
										// eslint-disable-next-line no-nested-ternary
										ideaGestureData.draggingIdea > 0
											? ' like'
											: ideaGestureData.draggingIdea < 0
											? ' dislike'
											: ''
									}`}
									{...handleGestures({ id })}
									style={calculateIdeaCardStyles({ id, index: i })}
								>
									{cloneElement(render({ id, ...rest }), {
										isScreenReaderHidden: i > 0,
									})}
								</div>

								{(ideaGestureData.currentIdeaId === id || !!ideaGestureData.ideaSwiped) &&
									ideaGestureData.xMovement !== 0 && (
										<div className="choice-indicator-overlay-container">
											<div
												className="choice-indicator-overlay"
												style={calculateSwipeIndicatorStyles()}
											>
												<img
													className="choice-indicator-icon"
													src={ideaGestureData.xMovement > 0 ? iconAccept : iconReject}
													alt="Accept"
													draggable="false"
												/>
											</div>
										</div>
									)}
							</>
						),
				)}
			</div>
			{renderButtons &&
				renderButtons({
					accept,
					reject,
					mouseX: ideaGestureData.xMovement ? ideaGestureData.xMovement : 0,
					resetDisabledButtons,
				})}
			{ideaTour.started && !ideaTour.finished && (
				<div
					className="idea-swipe-tour-overlay"
					onTouchStart={(e) => {
						e.preventDefault();
						e.stopPropagation();
					}}
					onTouchEnd={(e) => {
						e.preventDefault();
						e.stopPropagation();
						setIdeaTour({ ...ideaTour, finished: true });
						ideaTourRef.current = { ...ideaTour, finished: true };
						setIdeaGestureData(ideaGestureDataInitial);
					}}
					onMouseDown={(e) => {
						e.preventDefault();
						e.stopPropagation();
					}}
					onMouseUp={(e) => {
						e.preventDefault();
						e.stopPropagation();
						setIdeaTour({ ...ideaTour, finished: true });
						ideaTourRef.current = { ...ideaTour, finished: true };
						setIdeaGestureData(ideaGestureDataInitial);
					}}
				>
					<div
						className="swipe-gesture-icon-container"
						style={{
							transform: `translateX(${ideaGestureData.xMovement}px)`,
						}}
					>
						<div className="swipe-gesture-icon" />
					</div>
				</div>
			)}
		</>
	);
});

Stage.displayName = 'Stage';

Stage.propTypes = {
	onInterest: PropTypes.func,
	render: PropTypes.func,
	renderButtons: PropTypes.func,
	data: PropTypes.array,
	disableSwipe: PropTypes.bool,
};
export default Stage;
