// packages
import { useState, useEffect, useMemo } from 'react';

// constants
const KEYBOARD_NAVIGATION_KEYS = ['Tab', 'Enter', ' '];

/**
 * Hook to detect if the user is navigating via keyboard
 * @returns {boolean} True if user is using keyboard navigation
 */

const useIsKeyboardNavigation = () => {
	// state
	const [isKeyboardNavigation, setIsKeyboardNavigation] = useState(false);

	// life cycle
	useEffect(() => {
		const handleKeyDown = (event) => {
			if (KEYBOARD_NAVIGATION_KEYS.includes(event.key)) {
				setIsKeyboardNavigation(true); // The user is navigating with the keyboard
			}
		};

		const handlePointerInput = () => {
			setIsKeyboardNavigation(false); // The user is using mouse or touch
		};

		// Add event listeners
		window.addEventListener('keydown', handleKeyDown);
		window.addEventListener('mousedown', handlePointerInput);
		window.addEventListener('touchstart', handlePointerInput);
		window.addEventListener('mousemove', handlePointerInput);

		// Clean up event listeners on component unmount
		return () => {
			window.removeEventListener('keydown', handleKeyDown);
			window.removeEventListener('mousedown', handlePointerInput);
			window.removeEventListener('touchstart', handlePointerInput);
			window.removeEventListener('mousemove', handlePointerInput);
		};
	}, []);

	return useMemo(() => isKeyboardNavigation, [isKeyboardNavigation]);
};

export default useIsKeyboardNavigation;
