// packages
import { useEffect } from 'react';

/**
 * A custom hook that logs the currently focused DOM element
 * @param {boolean} [debugMode=false] - Enable/disable logging
 * @returns {void}
 */

const useLogFocusedElement = (debugMode = false) => {
	// life cycle
	useEffect(() => {
		const logFocusedElement = () => {
			if (!debugMode) return;

			try {
				console.log('Focused element:', document.activeElement);
			} catch (error) {
				console.warn('Error logging focused element:', error);
			}
		};

		// Add an event listener to log the focused element when focus changes
		document.addEventListener('focusin', logFocusedElement);

		// Clean up the event listener on component unmount
		return () => {
			document.removeEventListener('focusin', logFocusedElement);
		};
	}, [debugMode]);
};

export default useLogFocusedElement;
