// packages
import React from 'react';
import PropTypes from 'prop-types';

// utilities
import cn from 'src/utilities/bem-cn';

// components
import AssetPreviewModal from '../../shared/AssetPreviewModal';

// styles
import './styles.scss';

const mainClassName = 'body';
const el = (name) => cn(mainClassName, name);

export const MainContainer = ({ children, className }) => (
	<main className={`${mainClassName} ${className}`}>
		<div id="app-content" className={el('content')}>
			{children}
		</div>
		<AssetPreviewModal
			handleOnClose={() => {
				document.getElementById('app-content').style.filter = ''; // ? use refs instead
			}}
		/>
	</main>
);

MainContainer.propTypes = {
	children: PropTypes.node,
	className: PropTypes.string,
};

export default MainContainer;
