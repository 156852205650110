// packages
import React, { memo } from 'react';
import PropTypes from 'prop-types';

// utilities
import cn from 'src/utilities/bem-cn';

// styles
import './styles.scss';

const className = 'progress-bar';
const el = (name) => cn(className, name);

// constants
const BORDER_RADIUS_THRESHOLD = 97;
const BORDER_RADIUS_VALUE = '10px';

const ProgressBar = ({
	activeItem = 0,
	totalItemsCount = 0,
	label = '',
	screenReaderLabel = '',
	thumbnail = '',
	thumbnailLabel = '',
}) => (
	<div
		role="progressbar"
		aria-valuenow={activeItem}
		aria-valuemax={totalItemsCount}
		aria-label={screenReaderLabel || `You are on step ${activeItem + 1} of ${totalItemsCount}`}
		className={el('container')}
	>
		<div className={el('bar-info')}>
			{(!!thumbnail || !!thumbnailLabel) && (
				<div className={el('thumbnail-wrapper')}>
					{!!thumbnail && <img src={thumbnail} alt="" />}

					{!!thumbnailLabel && <p>{thumbnailLabel}</p>}
				</div>
			)}

			<span className={el('bar-percent')}>
				{label} {`${(activeItem + 1).toLocaleString()} / ${totalItemsCount.toLocaleString()}`}
			</span>
		</div>

		<div className={el('indicator')}>
			<div
				className={el('bar')}
				style={{
					width: `${(activeItem / totalItemsCount) * 100}%`,
					borderTopRightRadius:
						(activeItem / totalItemsCount) * 100 >= BORDER_RADIUS_THRESHOLD ? BORDER_RADIUS_VALUE : '0',
					borderBottomRightRadius:
						(activeItem / totalItemsCount) * 100 >= BORDER_RADIUS_THRESHOLD ? BORDER_RADIUS_VALUE : '0',
				}}
			/>
		</div>
	</div>
);

ProgressBar.propTypes = {
	activeItem: PropTypes.number,
	totalItemsCount: PropTypes.number,
	label: PropTypes.string,
	screenReaderLabel: PropTypes.string,
	thumbnail: PropTypes.string,
	thumbnailLabel: PropTypes.string,
};

export default memo(ProgressBar);
