// packages
import React, { useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';

// hooks
import useFocusLock from 'src/utilities/hooks/useFocusLock';

// styles
import './styles.scss';

const className = 'product-card-accessibility-wrapper';

/**
 * ProductCardAccessibilityWrapper component wraps the @upsiide/product-cards components
 * and enhances it with accessibility features including keyboard navigation
 * and ARIA attributes.
 */

const ProductCardAccessibilityWrapper = ({
	children,
	isScreenReaderHidden = false,
	showAccessibilityBugView = false,
	...rest
}) => {
	// state
	const [modalRef, setModalRef] = useState(null);

	const componentRef = useRef(null);

	useFocusLock(modalRef);

	// life cycle
	useEffect(() => {
		if (!componentRef?.current) return;

		// `imageOnly` commitment card carousel has hidden wrapper, which prevents SR from reading the children
		const carouselWrapper = componentRef.current.querySelector('.carousel__wrap');

		if (carouselWrapper) {
			carouselWrapper.setAttribute('aria-hidden', isScreenReaderHidden);
		}

		// Find `a` tag and add href attribute to make it accessible
		const imageZoomTrigger = componentRef.current.querySelector('.image-zoom-product-cards__preview-trigger');

		let imagePreviewModal;

		const handleModalKeyDown = (e, firstElement, lastElement) => {
			if (e.key === 'Tab') {
				// If Shift+Tab is pressed
				if (e.shiftKey) {
					if (document.activeElement === firstElement) {
						e.preventDefault();
						lastElement.focus();
					}
				}
				// If Tab is pressed without Shift
				else if (document.activeElement === lastElement) {
					e.preventDefault();
					firstElement.focus();
				}
			}
		};

		// Add the keydown event listener because the `a` tag in `@upsiide/product-cards` relies on multiple mouse events instead of `onClick`
		const handleImageZoomTriggerKeyDown = (e, imageZoomTriggerNode) => {
			if (!['Enter', ' '].includes(e.key) || !imageZoomTrigger) return;

			e.preventDefault(); // Prevent default scrolling behavior on Space
			e.stopPropagation(); // Prevent event bubbling

			// First, trigger the onMouseDown event on which `@upsiide/product-cards` stores the click position
			const mouseDownEvent = new MouseEvent('mousedown', {
				bubbles: true,
				cancelable: true,
				clientX: e.clientX, // Pass current mouse position
				clientY: e.clientY,
			});

			imageZoomTriggerNode.dispatchEvent(mouseDownEvent);

			// Use a timeout to ensure that onMouseDown is processed before onMouseUp
			setTimeout(() => {
				const mouseUpEvent = new MouseEvent('mouseup', {
					bubbles: true,
					cancelable: true,
					clientX: e.clientX, // Use the same position
					clientY: e.clientY,
				});

				imageZoomTriggerNode.dispatchEvent(mouseUpEvent);

				setTimeout(() => {
					// Focus lock on modal
					imagePreviewModal = document.querySelector('.image-zoom-product-cards__asset-window.expanded');

					if (imagePreviewModal) {
						// Lock focus on modal
						setModalRef(imagePreviewModal);

						// Add accessibility attributes to the close button on preview modal open
						// Close button is not a direct child of a product card
						const imageZoomCloseButton = document.querySelector('.image-zoom-product-cards__close');

						if (imageZoomCloseButton) {
							imageZoomCloseButton.setAttribute('aria-label', 'Close the image preview');
							imageZoomCloseButton.setAttribute('aria-hidden', isScreenReaderHidden);
							imageZoomCloseButton.setAttribute('tabindex', isScreenReaderHidden ? '-1' : '0'); // Make it focusable without `href` attribute
						}

						const images = document.querySelectorAll('.carousel__div-bg-image');

						if (images?.length) {
							images.forEach((img) => {
								img.setAttribute('aria-label', 'Product image');
								img.setAttribute('alt', 'Product image');
							});
						}
					}
				}, 100);
			}, 0); // Delay of 0 allows the browser to process the mousedown before the mouseup
		};

		if (imageZoomTrigger) {
			imageZoomTrigger.setAttribute('role', 'button');
			imageZoomTrigger.setAttribute('aria-label', 'Expand the image');
			imageZoomTrigger.setAttribute('aria-hidden', isScreenReaderHidden);
			imageZoomTrigger.setAttribute('tabindex', isScreenReaderHidden ? '-1' : '0'); // Make it focusable without `href` attribute
			imageZoomTrigger.addEventListener('keydown', (e) => handleImageZoomTriggerKeyDown(e, imageZoomTrigger));
		}

		const image = componentRef.current.querySelector('.carousel__div-bg-image');

		if (image) {
			image.setAttribute('alt', 'Product image');
			image.setAttribute('aria-label', 'Product image');
		}

		const commitmentPreviewTrigger = componentRef.current.querySelector('.commitment-view-product');

		let productPreviewModal;

		const handleCommitmentPreviewKeyDown = (e) => {
			if (!['Enter', ' '].includes(e.key) || !commitmentCheckbox) return;

			e.preventDefault(); // Prevent default scrolling behavior on Space

			// Commitment preview is not a button element so we need to simulate a click event
			commitmentPreviewTrigger.click();

			setTimeout(() => {
				// Focus lock on modal
				productPreviewModal = componentRef.current.querySelector(
					'.commitment-view-product__stack-product.open',
				);

				if (productPreviewModal) {
					const productPreviewImageZoomTrigger = componentRef.current.querySelector(
						'.image-zoom-product-cards__preview-trigger',
					);

					if (productPreviewImageZoomTrigger) {
						productPreviewImageZoomTrigger.setAttribute('aria-label', 'Expand the image');
						productPreviewImageZoomTrigger.setAttribute('aria-hidden', isScreenReaderHidden);
						productPreviewImageZoomTrigger.setAttribute('tabindex', isScreenReaderHidden ? '-1' : '0');
						productPreviewImageZoomTrigger.addEventListener('keydown', (event) =>
							handleImageZoomTriggerKeyDown(event, productPreviewImageZoomTrigger),
						);
					}

					// Add accessibility attributes to the close button on preview modal open
					// Close button is not a direct child of a product card
					const productPreviewCloseButton = componentRef.current.querySelector('.card-view-product__close');

					if (productPreviewCloseButton) {
						productPreviewCloseButton.setAttribute('aria-label', 'Close the product preview');
						productPreviewCloseButton.setAttribute('aria-hidden', isScreenReaderHidden);
					}

					const images = document.querySelectorAll('.carousel__div-bg-image');

					if (images?.length) {
						images.forEach((img) => {
							img.setAttribute('aria-label', 'Product image');
							img.setAttribute('alt', 'Product image');
						});
					}

					// Lock focus on modal
					const focusableElements = productPreviewModal.querySelectorAll(
						'a, button, textarea, input, select, [tabindex]:not([tabindex="-1"])',
					);

					const firstElement = focusableElements[0];
					const lastElement = focusableElements[focusableElements.length - 1];

					productPreviewModal.addEventListener('keydown', (event) =>
						handleModalKeyDown(event, firstElement, lastElement),
					);

					// Automatically set focus to the first focusable element
					firstElement?.focus();
				}
			}, 100);
		};

		if (commitmentPreviewTrigger) {
			commitmentPreviewTrigger.setAttribute('role', 'button');
			commitmentPreviewTrigger.setAttribute('aria-label', 'Preview the product');
			commitmentPreviewTrigger.setAttribute('aria-hidden', isScreenReaderHidden);
			commitmentPreviewTrigger.setAttribute('tabindex', isScreenReaderHidden ? '-1' : '0'); // Make it focusable without `href` attribute
			commitmentPreviewTrigger.addEventListener('keydown', handleCommitmentPreviewKeyDown);
		}

		const commitmentCheckboxWrapper = componentRef.current.querySelector('.commitment-view-product__checkbox');

		if (commitmentCheckboxWrapper) {
			commitmentCheckboxWrapper.setAttribute('aria-hidden', isScreenReaderHidden);
		}

		const commitmentCheckbox = componentRef.current.querySelector('.commitment-view-checkbox');

		const handleCommitmentCheckboxKeyDown = (e) => {
			if (!['Enter', ' '].includes(e.key) || !commitmentCheckbox) return;

			e.preventDefault(); // Prevent default scrolling behavior on Space

			// Commitment checkbox is not a button element so we need to simulate a click event
			commitmentCheckbox.click();
		};

		if (commitmentCheckbox) {
			commitmentCheckbox.setAttribute('aria-label', 'Select favorite');
			commitmentCheckbox.setAttribute('aria-hidden', isScreenReaderHidden);
			commitmentCheckbox.setAttribute('tabindex', isScreenReaderHidden ? '-1' : '0'); // Make it focusable

			commitmentCheckbox.addEventListener('keydown', handleCommitmentCheckboxKeyDown);
		}

		// Prevent hidden elements from being read by screen readers
		const ideaLabels = componentRef.current.querySelectorAll('.stack-card-section-text p');

		if (ideaLabels?.length) {
			ideaLabels.forEach((label) => {
				label.setAttribute('aria-hidden', isScreenReaderHidden);
			});
		}

		// Cleanup
		return () => {
			imageZoomTrigger && imageZoomTrigger.removeEventListener('keydown', handleImageZoomTriggerKeyDown);
			commitmentCheckbox && commitmentCheckbox.removeEventListener('keydown', handleCommitmentCheckboxKeyDown);
			imagePreviewModal && imagePreviewModal.removeEventListener('keydown', handleModalKeyDown);
			commitmentPreviewTrigger &&
				commitmentPreviewTrigger.removeEventListener('keydown', handleCommitmentPreviewKeyDown);
		};
	}, [isScreenReaderHidden]);

	const showAccessibilityBugViewClass = useMemo(() => {
		return showAccessibilityBugView ? 'show-acessibility-bug-view' : '';
	}, [showAccessibilityBugView]);

	return (
		<div ref={componentRef} {...{ className: `${className} ${showAccessibilityBugViewClass}`, ...rest }}>
			{children}
		</div>
	);
};

ProductCardAccessibilityWrapper.propTypes = {
	children: PropTypes.node.isRequired,
	isScreenReaderHidden: PropTypes.bool,
	showAccessibilityBugView: PropTypes.bool,
};

export default ProductCardAccessibilityWrapper;
