// packages
import React from 'react';
import PropTypes from 'prop-types';

// styles
import './styles.scss';

const className = 'basic-button';

const Button = ({ label, loading, disabled, onClick, tabindex, dataTestId, ...rest }) => (
	<button
		type="button"
		className={`${className} ${loading ? 'loading' : ''} ${disabled ? 'disabled' : ''}`}
		onClick={onClick}
		tabIndex={disabled || tabindex ? tabindex : 0}
		disabled={disabled}
		data-testid={dataTestId}
		aria-label={label}
		aria-busy={loading}
		aria-disabled={disabled}
		{...rest}
	>
		{!loading && label}
	</button>
);
Button.propTypes = {
	label: PropTypes.string,
	loading: PropTypes.bool,
	disabled: PropTypes.bool,
	onClick: PropTypes.func,
	tabindex: PropTypes.number,
	dataTestId: PropTypes.string,
};

export default Button;
